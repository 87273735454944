<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  All lost book
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\  All lost Book
                </div>
              </div>

            </div>
          </div>
          <div class="card-body">
            <div class="">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="loading">
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table">
                <thead>
                <tr class="px-3">
                  <th class="px-3 wrap-column">Name</th>
                  <th class="wrap-column">Info</th>
                  <th class="wrap-column">Accession no.</th>
                  <th class="wrap-column">Reported by.</th>
                </tr>
                </thead>
                <tbody>
                <tr v-show="lost_books.length > 0" v-for="(item,index) in lost_books" :key="index">
                  <td class="px-2 wrap-column" style="max-width: 400px !important; white-space: pre-wrap;">
                    <span  class="font-weight-black">
                      {{ item.book_title }}
                    </span>

                  </td>
                  <td class="wrap-column">
                    <div class="text-secondary">
                      Author: {{ item.book_author ? item.book_author : 'N/A' }}
                    </div>
                    <div class="text-secondary">
                      Publisher: {{ item.book_publisher ? item.book_publisher : 'N/A' }}
                    </div>
                    <div class="text-secondary">
                      Publication: {{ item.book_publication ? item.book_publication : 'N/A'  }}
                    </div>
                    <div class="text-secondary">
                      ISBN: {{item.book_isbn_no}}
                    </div>
                  </td>
                  <td class="wrap-column">
                    <span class="font-weight-medium">
                       {{item.accession_no}}
                    </span>
                  </td>
                  <td class="wrap-column">
                    <span class="font-weight-medium">
                       {{item.lost_reported_by_user}}
                    </span>
                  </td>
                </tr>
                <tr v-if="lost_books.length == 0">
                  <td class="text-center" colspan="4">
                    <strong>No data available to display.</strong>
                  </td>
                </tr>
                </tbody>
              </table>
              <b-pagination
                  v-if="lost_books.length > 0"
                  class="pull-right mt-7"
                  @input="getAllLostBooks"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
                  :disabled="loading"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import BookAccessionService from "@/core/services/library-book/book/BookAccessionService";
const bookAccession = new BookAccessionService;
export default {
  data(){
    return{
      loading: false,
      lost_books: [],
      page:null,
      total:null,
      perPage:null,
      search:{

      },
    }
  },
  methods:{
    getAllLostBooks(){
      this.loading=true;
      bookAccession
          .getAllLostBooks(this.search,this.page)
          .then(response => {
            this.lost_books=response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
          });

    },

  },
  mounted() {
    this.getAllLostBooks();
  }
}
</script>