import Apiservice from '@/core/services/api.service';
import { API_URL } from '../../../../common/config'
const apiService = Apiservice;

export default class BookAccessionService {
    #api = null;
    constructor() {
        this.#api = `${API_URL}user/library/book`;
    }
    getAllLostBooks(data={},index=null){
        let url = `${this.#api}/get/all/lost-book`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }
}